import React, { useEffect, useState, useRef, useMemo } from 'react';
import { motion } from 'framer-motion';
import { IoTrendingUp, IoTrendingDown } from 'react-icons/io5';

const animationCache = new Map();

const loadAnimationData = async (url) => {
  if (animationCache.has(url)) {
    return animationCache.get(url);
  }

  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const objectURL = URL.createObjectURL(blob);
    animationCache.set(url, objectURL);
    return objectURL;
  } catch (error) {
    console.error('Error loading animation:', error);
    return null;
  }
};

const CollectionCard = React.memo(({ collection }) => {
  const [animationData, setAnimationData] = useState(null);
  const [lottieLoaded, setLottieLoaded] = useState(false);
  const lottiePlayerRef = useRef(null);
  const isMounted = useRef(false);

  const lottieUrl = useMemo(() => collection.animation_url, [collection.animation_url]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Загрузка скрипта lottie-player только на клиенте
  useEffect(() => {
    if (typeof window !== 'undefined' && !window.lottiePlayerScriptAdded) {
      const script = document.createElement('script');
      script.src = "https://cdn.jsdelivr.net/npm/@lottiefiles/lottie-player@latest/dist/lottie-player.js";
      script.async = true;
      script.onload = () => {
        setLottieLoaded(true);
      };
      document.body.appendChild(script);
      window.lottiePlayerScriptAdded = true;
    } else {
      setLottieLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (!lottieUrl) return;
    const load = async () => {
      const data = await loadAnimationData(lottieUrl);
      if (isMounted.current) {
        setAnimationData(data);
      }
    };
    load();
  }, [lottieUrl]);

  const render24hChange = useMemo(() => {
    if (!collection.price_change_24h) return null;
    
    const value = parseFloat(collection.price_change_24h);
    const isPositive = value >= 0;
    const Icon = isPositive ? IoTrendingUp : IoTrendingDown;
    const color = isPositive ? 'text-green-500' : 'text-red-500';
    const formattedValue = value.toFixed(1);

    return (
      <p className={`${color} font-bold flex items-center`}>
        <Icon className="mr-1" />
        <span>24h: {formattedValue}%</span>
      </p>
    );
  }, [collection.price_change_24h]);

  return (
    <motion.div 
      className="bg-[#292929] backdrop-blur-lg rounded-xl p-4 cursor-pointer hover:scale-105 transition-transform"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center justify-between">
        <div className="text-left">
          <h3 className="py-1 text-2xl font-semibold">{collection.name_site}</h3>
          <p className="text-green-500 font-bold flex items-center">
            <img 
              src="data:image/svg+xml,%3csvg%20width='32'%20height='28'%20viewBox='0%200%2032%2028'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M31.144%205.84244L17.3468%2027.1579C17.1784%2027.4166%2016.9451%2027.6296%2016.6686%2027.7768C16.3922%2027.9241%2016.0817%2028.0009%2015.7664%2028C15.451%2027.9991%2015.141%2027.9205%2014.8655%2027.7716C14.59%2027.6227%2014.3579%2027.4084%2014.1911%2027.1487L0.664576%205.83477C0.285316%205.23695%200.0852825%204.54843%200.0869241%203.84647C0.104421%202.81116%200.544438%201.82485%201.31047%201.10385C2.0765%200.382844%203.10602%20-0.0139909%204.17322%200.000376986H27.6718C29.9143%200.000376986%2031.7391%201.71538%2031.7391%203.83879C31.7391%204.54199%2031.5333%205.23751%2031.1424%205.84244M3.98489%205.13003L14.0503%2020.1858V3.61156H5.03732C3.99597%203.61156%203.5291%204.28098%203.98647%205.13003M17.7742%2020.1858L27.8395%205.13003C28.3032%204.28098%2027.8285%203.61156%2026.7871%203.61156H17.7742V20.1858Z'%20fill='white'/%3e%3c/svg%3e" 
              alt="TON icon" 
              className="w-5 h-4 mr-1"
            />
            <span className="text-xs text-gray-400 mr-1">Floor Price</span>
            {collection.floor_price_tonnel}
          </p>
          <p className="text-red-500 font-bold">🔥 Burned: {collection.burned}</p>
          {render24hChange}
        </div>
        <div 
          style={{
            width: '150px',
            height: '150px',
            borderRadius: '20px',
            overflow: 'hidden',
            background: 'rgba(255, 255, 255, 0.2)'
          }}
        >
          {lottieUrl ? (
            animationData && lottieLoaded ? (
              <lottie-player
                ref={lottiePlayerRef}
                src={animationData}
                background="transparent"
                speed="1.0"
                autoplay
                style={{
                  width: '150px',
                  height: '150px',
                  borderRadius: '20px',
                  overflow: 'hidden'
                }}
              />
            ) : null
          ) : (
            <div className="w-full h-full flex items-center justify-center text-gray-400">
              No animation
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
});

export default CollectionCard;
