import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => (
  <div className="flex flex-col justify-center items-center h-screen">
    <h2 className="text-4xl font-bold mb-4">404 Not Found</h2>
    <Link to="/" className="text-blue-400 hover:underline">BACK</Link>
  </div>
);

export default NotFoundPage;
