import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaTelegram } from 'react-icons/fa';

const Navbar = () => {
  const location = useLocation();

  return (
    <nav className="fixed top-0 w-full z-50 p-2 bg-[#222932] backdrop-blur-lg shadow-md">
      <div className="max-w-7xl mx-auto flex flex-col items-center space-y-2">
        {/* Логотип и кнопка Telegram */}
        <div className="w-full flex justify-between items-center px-2">
          <Link 
            to="/" 
            className="flex items-center text-xl font-bold text-white" 
            style={{ fontFamily: 'Orbitron, sans-serif' }}
          >
            <img 
              src="data:image/svg+xml,%3csvg%20width='32'%20height='28'%20viewBox='0%200%2032%2028'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M31.144%205.84244L17.3468%2027.1579C17.1784%2027.4166%2016.9451%2027.6296%2016.6686%2027.7768C16.3922%2027.9241%2016.0817%2028.0009%2015.7664%2028C15.451%2027.9991%2015.141%2027.9205%2014.8655%2027.7716C14.59%2027.6227%2014.3579%2027.4084%2014.1911%2027.1487L0.664576%205.83477C0.285316%205.23695%200.0852825%204.54843%200.0869241%203.84647C0.104421%202.81116%200.544438%201.82485%201.31047%201.10385C2.0765%200.382844%203.10602%20-0.0139909%204.17322%200.000376986H27.6718C29.9143%200.000376986%2031.7391%201.71538%2031.7391%203.83879C31.7391%204.54199%2031.5333%205.23751%2031.1424%205.84244M3.98489%205.13003L14.0503%2020.1858V3.61156H5.03732C3.99597%203.61156%203.5291%204.28098%203.98647%205.13003M17.7742%2020.1858L27.8395%205.13003C28.3032%204.28098%2027.8285%203.61156%2026.7871%203.61156H17.7742V20.1858Z'%20fill='white'/%3e%3c/svg%3e" 
              alt="TON Icon" 
              className="w-5 mr-1.5"
            />
            <span className="text-white text-lg md:text-xl">NFTPRICE</span>
          </Link>

          <a 
            href="https://t.me/nftpricebot" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="flex items-center bg-[#0088cc] md:text-xl text-white text-xs rounded-lg px-3 py-1.5 font-medium hover:bg-[#0077b3] transition-colors"
          >
            <FaTelegram className="mr-1 text-base md:text-xl" />
            @nftpricebot
          </a>
        </div>

        <div className="pt-3 relative w-full overflow-x-auto overflow-y-hidden whitespace-nowrap max-w-full px-1 no-scrollbar">
          <div className="flex space-x-4 sm:space-x-6 pb-1 h-[30px] items-center">
            <NavLinkItem to="/" label="Collections" currentPath={location.pathname} />
            <NavLinkItem to="/market-analytics" label="Market Analytics" currentPath={location.pathname} />
            <NavLinkItem to="/recent-sales" label="Recent Sales" currentPath={location.pathname} />
          </div>
        </div>
      </div>
    </nav>
  );
};

const NavLinkItem = ({ to, label, currentPath }) => {
  const isActive = currentPath === to;
  return (
    <Link
      to={to}
      className={`text-base sm:text-lg relative ${
        isActive 
          ? 'text-white font-bold' 
          : 'text-gray-400 hover:text-white font-bold'
      } transition-colors`}
      style={{ fontFamily: 'Exo 2, sans-serif' }}
    >
      {label}
      {isActive && (
        <div className="absolute -bottom-1.5 left-0 right-0 h-[2px] bg-white rounded-full animate-underline" />
      )}
    </Link>
  );
};

export default Navbar;