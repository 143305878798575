import React, { useState, useEffect, useRef, useCallback } from 'react';
import api from '../services/api';
import CollectionCard from '../components/CollectionCard';
import Loader from '../components/Loader';
import { 
  FaSearch, 
  FaFilter,
  FaList,
  FaSort,
  FaTimes
} from 'react-icons/fa';
import { IoIosArrowRoundUp  } from 'react-icons/io';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';

const HomePage = () => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    search: '',
    category: 'All',
    sort: 'Price: High to Low',
    minPrice: '',
    maxPrice: '',
  });
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const TON_ICON = "data:image/svg+xml,%3csvg%20width='32'%20height='28'%20viewBox='0%200%2032%2028'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M31.144%205.84244L17.3468%2027.1579C17.1784%2027.4166%2016.9451%2027.6296%2016.6686%2027.7768C16.3922%2027.9241%2016.0817%2028.0009%2015.7664%2028C15.451%2027.9991%2015.141%2027.9205%2014.8655%2027.7716C14.59%2027.6227%2014.3579%2027.4084%2014.1911%2027.1487L0.664576%205.83477C0.285316%205.23695%200.0852825%204.54843%200.0869241%203.84647C0.104421%202.81116%200.544438%201.82485%201.31047%201.10385C2.0765%200.382844%203.10602%20-0.0139909%204.17322%200.000376986H27.6718C29.9143%200.000376986%2031.7391%201.71538%2031.7391%203.83879C31.7391%204.54199%2031.5333%205.23751%2031.1424%205.84244M3.98489%205.13003L14.0503%2020.1858V3.61156H5.03732C3.99597%203.61156%203.5291%204.28098%203.98647%205.13003M17.7742%2020.1858L27.8395%205.13003C28.3032%204.28098%2027.8285%203.61156%2026.7871%203.61156H17.7742V20.1858Z'%20fill='white'/%3e%3c/svg%3e";  
  
  const observerRef = useRef();
  const abortControllerRef = useRef(new AbortController());
  const filtersRef = useRef(filters);
  const pageRef = useRef(page);

  // Sync refs with current state
  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  useEffect(() => {
    pageRef.current = page;
  }, [page]);

  const fetchCollections = useCallback(async (reset = false) => {
    try {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      
      setLoading(true);
      if (reset) {
        setCollections([]);
        setPage(1);
      }

      const params = {
        limit: 3,
        offset: reset ? 0 : (pageRef.current - 1) * 3,
        sort: filtersRef.current.sort,
        ...(filtersRef.current.search && { search: filtersRef.current.search }),
        ...(filtersRef.current.category !== 'All' && { category: filtersRef.current.category }),
        ...(filtersRef.current.minPrice && { minPrice: filtersRef.current.minPrice }),
        ...(filtersRef.current.maxPrice && { maxPrice: filtersRef.current.maxPrice })
      };

      const response = await api.get('/api/collections', { 
        params,
        signal: abortControllerRef.current.signal
      });
      
      setHasMore(response.data.data.length === params.limit);
      
      if (reset) {
        setCollections(response.data.data);
      } else {
        setCollections(prev => [...prev, ...response.data.data]);
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error:', error);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  // Handle filter changes with debounce
  useEffect(() => {
    const debouncedFetch = debounce(() => {
      fetchCollections(true);
    }, 500);

    debouncedFetch();
    return () => {
      debouncedFetch.cancel();
      abortControllerRef.current.abort();
    };
  }, [filters]);

  // Handle pagination
  useEffect(() => {
    if (page > 1) {
      fetchCollections();
    }
  }, [page]);

  const lastElementRef = useCallback(node => {
    if (loading || !hasMore) return;
    
    if (observerRef.current) observerRef.current.disconnect();
    
    observerRef.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && !loading) {
        setPage(prev => prev + 1);
      }
    });
    
    if (node) observerRef.current.observe(node);
  }, [loading, hasMore]);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollToTop(window.scrollY > 200);
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleFilterChange = (name, value) => {
    setFilters(prev => {
      const newFilters = { ...prev };
  
      if (name === 'minPrice' || name === 'maxPrice') {
        const numericValue = parseFloat(value);
        
        if (!isNaN(numericValue)) {
          if (name === 'minPrice') {
            newFilters[name] = Math.max(0, Math.min(numericValue, 1000000));
          } else {
            const minPrice = parseFloat(prev.minPrice) || 0;
            newFilters[name] = Math.max(minPrice, Math.min(numericValue, 1000000));
          }
        } else {
          newFilters[name] = '';
        }
      } else {
        newFilters[name] = value;
      }

      const min = parseFloat(newFilters.minPrice) || 0;
      const max = parseFloat(newFilters.maxPrice) || Infinity;
  
      if (min > max) {
        if (name === 'minPrice') {
          newFilters.maxPrice = newFilters.minPrice;
        } else {
          newFilters.minPrice = newFilters.maxPrice;
        }
      }
  
      return newFilters;
    });
  };

  const resetFilters = () => {
    setFilters({
      search: '',
      category: 'All',
      sort: 'Price: High to Low',
      minPrice: '',
      maxPrice: '',
    });
  };

  const activeFilters = [
    filters.minPrice && { 
      key: 'minPrice', 
      label: (
        <span className="flex items-center gap-1">
          Min: <img src={TON_ICON} className="w-4 h-4" alt="TON"/> {filters.minPrice}
        </span>
      ) 
    },
    filters.maxPrice && { 
      key: 'maxPrice', 
      label: (
        <span className="flex items-center gap-1">
          Max: <img src={TON_ICON} className="w-4 h-4" alt="TON"/> {filters.maxPrice}
        </span>
      ) 
    },
  ].filter(Boolean);

  return (
    <div className="min-h-screen bg-[#181818] text-white">
      <div className="mx-auto p-4 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
        <div className="text-center mb-6">
          <h1 className="text-2xl md:text-3xl font-bold mb-2">
            Track the Value of Your NFTs
          </h1>
          <p className="text-[#B0B0B0] text-sm md:text-xl">
            Stay ahead of the market with real-time analytics on NFT collections and their prices.
            Empower your NFT journey with data-backed tools and stay on top of the market.
          </p>
        </div>

        <div className="mb-6 space-y-4">
          <div className="flex items-center gap-4">
            <div className="relative flex-1">
              <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-[#B0B0B0]" />
              <input
                type="text"
                placeholder="Enter a Collection name..."
                className="w-full p-3 pl-12 pr-3 rounded-xl bg-[#292929] text-white placeholder-[#B0B0B0] focus:outline-none"
                value={filters.search}
                onChange={(e) => handleFilterChange('search', e.target.value)}
              />
            </div>
          
            <button 
              onClick={() => {
               
                setShowFilters(!showFilters);
              }}
              className="p-3 rounded-xl bg-[#292929] hover:bg-[#363636] transition-colors"
            >
              {showFilters ? (
                <FaTimes size={23} className="text-[#B0B0B0]" />
              ) : (
                <FaFilter size={23} className="text-[#B0B0B0]" />
              )}
            </button>
          </div>

          {showFilters && (
            <div className="bg-[#232323] rounded-xl p-4 space-y-4">
              {activeFilters.length > 0 && (
                <div className="flex flex-wrap gap-2">
                  {activeFilters.map((filter) => (
                    <button
                      key={filter.key}
                      onClick={() => handleFilterChange(filter.key, '')}
                      className="flex items-center gap-1 px-3 py-1 bg-[#363636] rounded-full text-sm hover:bg-[#444444]"
                    >
                      <span>{filter.label}</span>
                      <FaTimes className="text-xs" />
                    </button>
                  ))}
                </div>
              )}

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="space-y-2">
                  <div className="flex items-center gap-2 text-[#B0B0B0]">
                    <FaList />
                    <label className="text-sm">Category</label>
                  </div>
                  
                  <select
                    className="w-full p-2 pr-8 rounded-md bg-[#292929] appearance-none bg-select-arrow bg-no-repeat bg-[length:16px_16px] bg-[center_right_0.5rem]"                    value={filters.category}
                    onChange={(e) => handleFilterChange('category', e.target.value)}
                  >
                    {['All', 'Gifts', 'Number', 'Nickname', 'Other'].map(opt => {
                      // Оставляем "Gifts" и "All" без изменений, для остальных добавляем (soon)
                      const label = (opt !== 'Gifts' && opt !== 'All') ? `${opt} (soon)` : opt;
                      return (
                        <option key={opt} value={opt}>
                          {label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="space-y-2">
                  <div className="flex items-center gap-2 text-[#B0B0B0]">
                    <img 
                      src={TON_ICON} 
                      className="w-4 h-4" 
                      alt="TON"
                      style={{ filter: 'brightness(0.7)' }}
                    />
                    <label className="text-sm">Price Range</label>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="number"
                      placeholder="From"
                      className="w-full p-2 rounded-md bg-[#292929]"
                      value={filters.minPrice}
                      min="0"
                      max="1000000"
                      onChange={(e) => handleFilterChange('minPrice', e.target.value)}
                    />
                    <input
                      type="number"
                      placeholder="To"
                      className="w-full p-2 rounded-md bg-[#292929]"
                      value={filters.maxPrice}
                      min={filters.minPrice || 0}
                      max="1000000"
                      onChange={(e) => handleFilterChange('maxPrice', e.target.value)}
                    />
                  </div>
                </div>

                <div className="space-y-2">
                  <div className="flex items-center gap-2 text-[#B0B0B0]">
                    <FaSort />
                    <label className="text-sm">Sort By</label>
                  </div>
                  <select
                    className="w-full p-2 pr-8 rounded-md bg-[#292929] appearance-none bg-select-arrow bg-no-repeat bg-[length:16px_16px] bg-[center_right_0.5rem]"
                    value={filters.sort}
                    onChange={(e) => handleFilterChange('sort', e.target.value)}
                  >
                    {[ 'Price: High to Low','Price: Low to High', 'Newest', 'Popular'].map(opt => (
                      <option key={opt} value={opt}>{opt}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>

        {collections.length === 0 && !loading && (
          <div className="text-center py-8 text-gray-400">
            {filters.search ? `No results found for "${filters.search}"` : "No collections available"}
          </div>
        )}

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-5 md:gap-5">
          {collections.map((collection, index) => (
            <Link
              to={`/collections/${collection.name}`}
              key={collection.collection_id}
              ref={index === collections.length - 1 ? lastElementRef : null}
            >
              <CollectionCard collection={collection} />
            </Link>
          ))}
        </div>

        {loading && <Loader className="my-8" />}

        {!hasMore && collections.length > 0 && (
          <div className="text-center py-4 text-gray-400">
            No more collections to load...
          </div>
        )}

        {showScrollToTop && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-8 right-8 text-3xl p-3 bg-[#292929] rounded-full shadow-md transition-colors"
            aria-label="Scroll to top"
          >
            <IoIosArrowRoundUp className="text-white" />
          </button>
        )}
      </div>
    </div>
  );
};

export default HomePage;