// src/App.js
import React, { useEffect } from 'react';
import WebApp from '@twa-dev/sdk';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Импорты всех страниц
import HomePage from './pages/HomePage';
import CollectionPage from './pages/CollectionPage';
import NotFoundPage from './pages/NotFoundPage';
import MarketAnalPage from './pages/MarketAnalPage'; 
import RecentSalesPage from './pages/RecentSalesPage';


import Navbar from './components/Navbar';

function App() {
  useEffect(() => {
    WebApp.ready(); // Инициализируем Telegram WebApp
  }, []);

  return (
    <BrowserRouter>
      {/* Фиксированный Navbar */}
      <Navbar />

      <div
        className="min-h-screen text-white pt-[93px] md:pt-[105px]"
        style={{
          margin: 0,
          backgroundColor: WebApp.themeParams.bg_color || '#181818',
        }}
      >
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/collections/:slug" element={<CollectionPage />} />
          <Route path="/market-analytics" element={<MarketAnalPage />} />
          <Route path="/recent-sales" element={<RecentSalesPage />} />
      
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
