import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import api from '../services/api';
import Loader from '../components/Loader';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts';
import { motion } from 'framer-motion';
import { IoIosArrowBack } from 'react-icons/io';
import { FaBoxOpen, FaTelegram, FaBullseye, FaFire, FaStar } from 'react-icons/fa';

const ICON_URL = "data:image/svg+xml,%3csvg%20width='32'%20height='28'%20viewBox='0%200%2032%2028'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M31.144%205.84244L17.3468%2027.1579C17.1784%2027.4166%2016.9451%2027.6296%2016.6686%2027.7768C16.3922%2027.9241%2016.0817%2028.0009%2015.7664%2028C15.451%2027.9991%2015.141%2027.9205%2014.8655%2027.7716C14.59%2027.6227%2014.3579%2027.4084%2014.1911%2027.1487L0.664576%205.83477C0.285316%205.23695%200.0852825%204.54843%200.0869241%203.84647C0.104421%202.81116%200.544438%201.82485%201.31047%201.10385C2.0765%200.382844%203.10602%20-0.0139909%204.17322%200.000376986H27.6718C29.9143%200.000376986%2031.7391%201.71538%2031.7391%203.83879C31.7391%204.54199%2031.5333%205.23751%2031.1424%205.84244M3.98489%205.13003L14.0503%2020.1858V3.61156H5.03732C3.99597%203.61156%203.5291%204.28098%203.98647%205.13003M17.7742%2020.1858L27.8395%205.13003C28.3032%204.28098%2027.8285%203.61156%2026.7871%203.61156H17.7742V20.1858Z'%20fill='white'/%3e%3c/svg%3e";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const dateObj = new Date(label);
    const formattedDate = dateObj.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
    
    return (
      <div className="bg-[#1a1a1a] border border-[#ffffff20] rounded-xl p-3 shadow-lg min-w-[180px]">
        <p className="text-white font-semibold mb-2">{formattedDate}</p>
        {payload.map((entry, index) => (
          <div key={index} className="flex items-center justify-between mb-1 last:mb-0">
            <div className="flex items-center">
              <div 
                className="w-2 h-2 rounded-full mr-2"
                style={{ backgroundColor: entry.color }}
              />
              <span className="text-sm capitalize">{entry.name}</span>
            </div>
            <div className="flex items-center">
              <img src={ICON_URL} alt="TON" className="w-4 h-4 mr-1" />
              <span className="text-sm">{entry.value.toFixed(2)}</span>
            </div>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

const CollectionPage = () => {
  const { slug } = useParams();
  const [collection, setCollection] = useState(null);
  const [loading, setLoading] = useState(true);
  const [nftNumber, setNftNumber] = useState('');
  const [nftValue, setNftValue] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [nftDetails, setNftDetails] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [timeFrame, setTimeFrame] = useState('30d');
  const [activeChart, setActiveChart] = useState('tonnel');

  useEffect(() => {
    api.get(`/api/collections/${slug}`)
      .then(response => setCollection(response.data))
      .catch(error => console.error(error))
      .finally(() => setLoading(false));
  }, [slug]);
  useEffect(() => {
    // Чтобы каждый раз при смене коллекции/slug/timeFrame показывать спиннер
    setLoading(true);
  
    api.get(`/api/collections/${slug}`)
      .then((response) => {
        setCollection(response.data);
        return response.data; // чтобы воспользоваться результатом ниже
      })
      .then((collectionData) => {
        if (collectionData?.name) {
          return api.get('/api/price_history', {
            params: {
              collection_name: collectionData.name,
              timeframe: timeFrame,
            },
          });
        }
      })
      .then((priceResponse) => {
        if (priceResponse?.data) {
          setChartData(
            priceResponse.data.sort((a, b) => new Date(a.date) - new Date(b.date))
          );
        }
      })
      .catch((error) => console.error('Ошибка при загрузке:', error))
      .finally(() => setLoading(false));
  }, [slug, timeFrame]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setNftDetails(null);

    if (!nftNumber || isNaN(nftNumber)) {
      setError('Please enter a valid NFT number');
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await api.get('/api/nft', {
        params: { collection_id: collection.collection_id, number: parseInt(nftNumber) }
      });
      setNftValue(response.data.calculated_price);
      setNftDetails(response.data);
    } catch (error) {
      console.error('Error fetching NFT value:', error);
      setError(error.response?.data?.error || 'Failed to fetch NFT details');
      setNftValue(null);
    } finally {
      setIsSubmitting(false);
    }
  };

  const truncateIfLong = (str = '', maxLength = 15) => 
    str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;

  const formatXAxisDate = (value) => 
    new Date(value).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

  const renderChart = (marketplace) => (
    <div className="bg-[#232323] rounded-2xl p-6 border border-[#ffffff10] mb-6 h-[400px] sm:h-[500px]">
      <div className="flex justify-between items-center mb-6 gap-3">
        <div className="flex items-center space-x-2">
          <img src={ICON_URL} alt="TON" className="w-6 h-6" />
          <span className="text-white font-bold">
            {marketplace.toUpperCase()}
          </span>
        </div>
        <div className="flex flex-wrap gap-2 justify-center">
          {['7d', '30d', '3m', '1y'].map((tf) => (
            <button
              key={tf}
              onClick={() => setTimeFrame(tf)}
              className={`px-2 py-1 rounded-lg text-[10px] sm:text-xs font-semibold border ${
                timeFrame === tf 
                  ? 'bg-[#0088cc] border-[#0088cc]' 
                  : 'border-[#ffffff30] hover:bg-[#0088cc]'
              }`}
            >
              {tf.toUpperCase()}
            </button>
          ))}
        </div>
      </div>
      <ResponsiveContainer width="100%" height="85%">
        <LineChart 
          data={chartData} 
          margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="2 2" stroke="#ffffff15" />
          <XAxis
            dataKey="date"
            stroke="#ffffff60"
            tick={{ 
              fill: '#ffffff90',
              fontSize: window.innerWidth < 640 ? 10 : 12
            }}
            axisLine={{ stroke: '#ffffff30' }}
            tickFormatter={formatXAxisDate}
            interval={window.innerWidth < 640 ? 3 : 0}
          />
          <YAxis
            stroke="#ffffff60"
            tick={{ 
              fill: '#ffffff90',
              fontSize: window.innerWidth < 640 ? 10 : 12
            }}
            axisLine={{ stroke: '#ffffff30' }}
            width={window.innerWidth < 640 ? 30 : 40}
            tickFormatter={value => `${value.toFixed(0)}`}
          />
          <Tooltip 
            content={<CustomTooltip />}
            offset={window.innerWidth < 640 ? -50 : 0}
            isAnimationActive={false}
            trigger="hover"
          />
          <Line 
            type="monotone" 
            dataKey={`${marketplace}.price`}
            stroke={marketplace === 'tonnel' ? '#0088cc' : '#00cc88'}
            strokeWidth={2}
            dot={false}
            activeDot={{ r: window.innerWidth < 640 ? 4 : 6 }}
          />
          <Line 
            type="monotone" 
            dataKey={`${marketplace}.average`}
            stroke={marketplace === 'tonnel' ? '#0088cc80' : '#00cc8880'}
            strokeWidth={1}
            dot={false}
            strokeDasharray="4 4"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );

  if (loading) return <Loader />;
  if (!collection) return <div className="text-white">Collection not found</div>;

  return (
    <div className="min-h-screen bg-[#181818] text-white">
      <div className="mx-auto p-4 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
        <Link to="/" className="flex mb-3">
          <IoIosArrowBack size={30} className="mr-2" />
        </Link>

        <motion.div 
          className="bg-[#232323] rounded-2xl p-8 border border-[#ffffff10] mb-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h2 className="flex items-center justify-center text-4xl font-bold mb-6 bg-gradient-to-r from-[#ff6f61] to-[#d77bf7] bg-clip-text text-transparent gap-2">
            {collection?.png_url && (
              <img
                src={collection.png_url}
                alt={collection.name_site}
                className="w-16 h-16 object-contain rounded-lg"
                onError={(e) => {
                  e.target.style.display = 'none';
                  console.error('Failed to load collection image:', collection.png_url);
                }}
              />
            )}
            {collection?.name_site}
          </h2>

          <form onSubmit={handleSubmit} className="mt-8 flex flex-col sm:flex-row items-center justify-center gap-4">
            <div className="relative w-full sm:w-96">
              <input
                type="number"
                placeholder="🔢 Enter your NFT number"
                className="w-full px-6 py-4 rounded-xl bg-[#292929] border border-[#ffffff15] text-lg placeholder-[#ffffff50] focus:outline-none focus:ring-2 focus:ring-[#00bcd4]"
                value={nftNumber}
                onChange={(e) => setNftNumber(e.target.value)}
              />
            </div>
            <button 
              type="submit" 
              className="px-8 py-4 bg-[#0088cc] text-lg font-semibold rounded-xl hover:scale-105 transition-transform flex items-center gap-2 disabled:opacity-50"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <span className="animate-spin">⏳</span> Calculating...
                </>
              ) : (
                <>🔍 Calculated</>
              )}
            </button>
          </form>

          {(nftValue !== null || error) && (
            <motion.div 
              className="mt-8 p-4 rounded-xl border border-[#00bcd430]"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
            >
              {error ? (
                <p className="text-xl font-semibold text-red-400">❌ {error}</p>
              ) : (
                <div>
                  <p className="text-2xl font-semibold text-center">
                    💰 Your NFT's value ~
                    <span className="ml-1 inline-flex items-center text-[#d77bf7]">
                      {nftValue}
                      <img src={ICON_URL} alt="TON" className="ml-1 w-5 h-5 mr-1" />
                    </span>
                    *
                  </p>
                  {nftDetails && (
                    <div className="mt-4 grid grid-cols-2 gap-5 text-1xl">
                      <p className="col-span-2 text-center">
                        🏷️ Model: <span className="font-bold">{nftDetails.model}</span>
                      </p>
                      <p className="col-span-2 text-center">
                        👤 <span className="font-bold ml-1">{truncateIfLong(nftDetails.owner, 18)}</span>
                      </p>
                      <div className="flex items-center justify-center col-span-2">
                        <a
                          href="https://t.me/tonnel_network_bot/gifts?startapp=ref_6147955723"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex items-center bg-[#0088cc] text-white font-bold rounded-full px-4 py-2"
                        >
                          <FaTelegram size={24} className="mr-2" />
                          Buy This NFT
                        </a>
                      </div>
                      <p className="italic font-bold text-center text-xs mt-2 col-span-2">
                        * Note: price is estimated by model's average price...
                      </p>
                    </div>
                  )}
                </div>
              )}
            </motion.div>
          )}
        </motion.div>

        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mb-12">
          {[
            { icon: <FaBoxOpen className="text-4xl" />, title: 'available NFTs', value: collection.quantity },
            { icon: <FaBullseye className="text-4xl" />, title: 'started quantity', value: collection.sale_quantity },
            { icon: <FaFire className="text-4xl" />, title: 'burned', value: collection.burned },
            { icon: <FaStar className="text-4xl" />, title: 'started price', value: collection.start_price_stars },
          ].map((item, index) => (
            <motion.div
              key={index}
              className="bg-[#232323] p-6 rounded-2xl border border-[#ffffff10] flex flex-col items-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="mb-4">{item.icon}</div>
              <p className="text-sm text-[#ffffff80] mb-2 text-center">{item.title}</p>
              <p className="text-2xl font-bold text-white">{item.value}</p>
            </motion.div>
          ))}
        </div>

        <div className="grid gap-6 mb-12">
          <div className="flex gap-4 justify-center mb-4">
            <button
              onClick={() => setActiveChart('tonnel')}
              className={`px-4 py-2 rounded-xl font-semibold ${
                activeChart === 'tonnel'
                  ? 'bg-[#0088cc] text-white'
                  : 'bg-[#ffffff10] text-[#ffffff80] hover:bg-[#0088cc30]'
              }`}
            >
              Tonnel Chart
            </button>
            <button
              onClick={() => setActiveChart('getgems')}
              className={`px-4 py-2 rounded-xl font-semibold ${
                activeChart === 'getgems'
                  ? 'bg-[#00cc88] text-white'
                  : 'bg-[#ffffff10] text-[#ffffff80] hover:bg-[#00cc8830]'
              }`}
            >
              Getgems Chart
            </button>
          </div>

          {activeChart === 'tonnel' ? renderChart('tonnel') : renderChart('getgems')}
        </div>
      </div>
    </div>
  );
};

export default CollectionPage;