import React, { useEffect, useState } from 'react';
import { Bar, Chart as ChartJS } from 'react-chartjs-2';
import 'chart.js/auto';
import api from '../services/api';
import { 
  IoRocket, IoFlame, IoRepeat, IoStatsChart, 
  IoTrendingUp, IoTrendingDown, IoAnalytics,
} from 'react-icons/io5';

const Spinner = () => (
  <div className="flex items-center justify-center">
    <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-white"></div>
  </div>
);

const TON_ICON = "data:image/svg+xml,%3csvg%20width='32'%20height='28'%20viewBox='0%200%2032%2028'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M31.144%205.84244L17.3468%2027.1579C17.1784%2027.4166%2016.9451%2027.6296%2016.6686%2027.7768C16.3922%2027.9241%2016.0817%2028.0009%2015.7664%2028C15.451%2027.9991%2015.141%2027.9205%2014.8655%2027.7716C14.59%2027.6227%2014.3579%2027.4084%2014.1911%2027.1487L0.664576%205.83477C0.285316%205.23695%200.0852825%204.54843%200.0869241%203.84647C0.104421%202.81116%200.544438%201.82485%201.31047%201.10385C2.0765%200.382844%203.10602%20-0.0139909%204.17322%200.000376986H27.6718C29.9143%200.000376986%2031.7391%201.71538%2031.7391%203.83879C31.7391%204.54199%2031.5333%205.23751%2031.1424%205.84244M3.98489%205.13003L14.0503%2020.1858V3.61156H5.03732C3.99597%203.61156%203.5291%204.28098%203.98647%205.13003M17.7742%2020.1858L27.8395%205.13003C28.3032%204.28098%2027.8285%203.61156%2026.7871%203.61156H17.7742V20.1858Z'%20fill='white'/%3e%3c/svg%3e";

const ChartsPage = () => {
  const [marketTrends, setMarketTrends] = useState(null);
  const [volumeData, setVolumeData] = useState([]);

  useEffect(() => {
    const loadMarketData = async () => {
      try {
        const [trendsRes, volumeRes] = await Promise.all([
          api.get('/api/market_trends'),
          api.get('/api/volume_sales_history')
        ]);
        
        if (!trendsRes.data?.error) setMarketTrends(trendsRes.data);
        if (!volumeRes.data?.error) setVolumeData(volumeRes.data);
      } catch (error) {
        console.error('Error loading market data:', error);
      }
    };

    loadMarketData();
  }, []);
  const formatNumber = num => {
    // Handle string values and convert to number
    if (typeof num === 'string') {
      const cleaned = num.replace(/[^0-9.]/g, '')
      if (!cleaned) return '0'
      num = parseFloat(cleaned)
    }
  
    if (typeof num !== 'number' || isNaN(num)) return '0'
  
    if (num >= 1e6) {
      const millions = num / 1e6
      return `${millions % 1 === 0 ? millions : millions.toFixed(1)}M`.replace('.0M', 'M')
    }
    
    if (num >= 1e3) {
      const thousands = num / 1e3
      return `${thousands % 1 === 0 ? thousands : thousands.toFixed(1)}K`.replace('.0K', 'K')
    }
  
    return num.toLocaleString()
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { labels: { color: '#fff', font: { size: 14 } } }
    },
    scales: {
      x: {
        ticks: { 
          color: '#fff',
          maxRotation: 45,
          minRotation: 45
        }
      },
      y: { 
        ticks: { color: '#fff' },
        title: { text: 'Volume (TON)', display: true }
      },
      y1: {
        position: 'right',
        ticks: { color: '#fff' },
        title: { text: 'Sales Count', display: true },
        grid: { drawOnChartArea: false }
      }
    }
  };

  const volumeChartData = {
    labels: volumeData.map(item => item.date),
    datasets: [
      {
        type: 'bar',
        label: 'Daily Volume (TON)',
        data: volumeData.map(item => item.volume),
        backgroundColor: '#10b981',
        yAxisID: 'y'
      },
      {
        type: 'line',
        label: 'Daily Sales',
        data: volumeData.map(item => item.sales),
        borderColor: '#3b82f6',
        tension: 0.1,
        yAxisID: 'y1'
      }
    ]
  };

  return (
    <div className="min-h-screen bg-[#181818] text-white">
      <div className="mx-auto px-4 lg:px-8 max-w-7xl">
        <section className="py-8 space-y-8">
        
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-3">
            <StatCard 
              icon={marketTrends?.market_stats?.['24h_change']?.startsWith('-') 
                ? <IoTrendingDown/> 
                : <IoTrendingUp/>}
              title="24h Change"
              value={marketTrends?.market_stats?.['24h_change'] ? `${marketTrends.market_stats['24h_change']}%` : <Spinner/>}
            />
            <StatCard 
              icon={<IoStatsChart/>} 
              title="Avg Price" 
              value={marketTrends?.market_stats?.average_price || <Spinner/>} 
              isTon 
            />
            <StatCard 
              icon={<IoRocket/>} 
              title="Volume" 
              value={marketTrends?.market_stats?.total_volume ? formatNumber(marketTrends.market_stats.total_volume) : <Spinner/>} 
              isTon 
            />
            <StatCard 
              icon={<IoFlame/>} 
              title="Burned" 
              value={marketTrends?.market_stats?.total_burned ? formatNumber(marketTrends.market_stats.total_burned) : <Spinner/>} 
            />
            <StatCard 
              icon={<IoRepeat/>} 
              title="Trades" 
              value={marketTrends?.market_stats?.total_transactions ? formatNumber(marketTrends.market_stats.total_transactions) : <Spinner/>} 
            />
            <StatCard 
              icon={<IoAnalytics/>} 
              title="Market Cap" 
              value={marketTrends?.market_stats?.market_cap ? formatNumber(marketTrends.market_stats.market_cap) : <Spinner/>} 
              isTon 
            />
          </div>
          <p className="text-sm md:text-xl text-center mx-auto text-gray-300 max-w-2xl">
              Real-time total market insights and comprehensive statistics for The Open Network NFT ecosystem. 
              Track volume, sales, market trends and top collections.
            </p>
          <div className="grid lg:grid-cols-1 gap-8">
            <ChartSection title="Volume & Sales (7d)">
              {volumeData.length > 0 ? (
                <ChartJS
                  data={volumeChartData}
                  options={chartOptions}
                />
              ) : (
                <div className="text-center py-4 text-gray-400">Loading market data...</div>
              )}
            </ChartSection>

            <ChartSection title="Top Collections (7d)">
              {marketTrends?.trending_collections?.length > 0 ? (
                <Bar
                  data={{
                    labels: marketTrends.trending_collections.map(c => c.name),
                    datasets: [{
                      label: 'Volume (TON)',
                      data: marketTrends.trending_collections.map(c => c.total_volume),
                      backgroundColor: '#8b5cf6'
                    }]
                  }}
                  options={chartOptions}
                />
              ) : (
                <div className="text-center py-4 text-gray-400">No volume data</div>
              )}
            </ChartSection>
          </div>
        </section>
      </div>
    </div>
  );
};

const StatCard = ({ icon, title, value, isTon }) => (
  <div className="bg-gradient-to-br from-[#292929] to-[#1f1f1f] p-4 rounded-xl">
    <div className="flex items-center gap-3 text-sm lg:text-lg mb-3 text-gray-100">
      {React.cloneElement(icon, { className: "w-5 h-5 lg:w-6 lg:h-6 " })}
      <span className="font-medium">{title}</span>
    </div>
    <div className="text-xl lg:text-3xl font-bold">
      {value}
      {isTon && <img src={TON_ICON} className="w-5 h-5 lg:w-7 lg:h-7 inline ml-1.5 transform -translate-y-1"/>}
    </div>
  </div>
);
const ChartSection = ({ title, children }) => (
  <div className="bg-[#292929] p-4 rounded-xl">
    <h3 className="text-lg font-semibold mb-4">{title}</h3>
    <div className="h-64 md:h-80 lg:h-96">
      {children}
    </div>
  </div>
);

export default ChartsPage;