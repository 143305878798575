import React, { useState, useEffect, useRef, useCallback } from 'react';
import api from '../services/api';
import Loader from '../components/Loader';
import { 
  FaMoneyBillWave, 
  FaExchangeAlt, 
  FaGift,
  FaSearch,
  FaSort,
  FaFilter,
  FaList,
  FaTimes
} from 'react-icons/fa';
import { IoIosArrowRoundUp } from 'react-icons/io';
import debounce from 'lodash.debounce';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('en-US');

const TON_ICON = "data:image/svg+xml,%3csvg%20width='32'%20height='28'%20viewBox='0%200%2032%2028'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3e%3cpath%20d='M31.144%205.84244L17.3468%2027.1579C17.1784%2027.4166%2016.9451%2027.6296%2016.6686%2027.7768C16.3922%2027.9241%2016.0817%2028.0009%2015.7664%2028C15.451%2027.9991%2015.141%2027.9205%2014.8655%2027.7716C14.59%2027.6227%2014.3579%2027.4084%2014.1911%2027.1487L0.664576%205.83477C0.285316%205.23695%200.0852825%204.54843%200.0869241%203.84647C0.104421%202.81116%200.544438%201.82485%201.31047%201.10385C2.0765%200.382844%203.10602%20-0.0139909%204.17322%200.000376986H27.6718C29.9143%200.000376986%2031.7391%201.71538%2031.7391%203.83879C31.7391%204.54199%2031.5333%205.23751%2031.1424%205.84244M3.98489%205.13003L14.0503%2020.1858V3.61156H5.03732C3.99597%203.61156%203.5291%204.28098%203.98647%205.13003M17.7742%2020.1858L27.8395%205.13003C28.3032%204.28098%2027.8285%203.61156%2026.7871%203.61156H17.7742V20.1858Z'%20fill='white'/%3e%3c/svg%3e";

const RecentSalesPage = () => {
  const [sales, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    search: '',
    timeframe: '24h',
    type: 'ALL',
    most_sold: false,
    sort: 'price_desc',
  });
  const [pagination, setPagination] = useState({
    page: 1,
    hasMore: true,
    total: 0
  });
  const [limit, setLimit] = useState(10);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  // Новый стейт для управления отображением дополнительных фильтров
  const [showFilters, setShowFilters] = useState(false);

  const observerRef = useRef();
  const abortControllerRef = useRef(new AbortController());

  useEffect(() => {
    const handleResize = debounce(() => {
      setLimit(window.innerWidth >= 1024 ? 20 : 10);
    }, 250);
    
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setSales([]);
    setPagination({ page: 1, hasMore: true, total: 0 });
    fetchSales(true);
  }, [limit]);

  const fetchSales = useCallback(async (reset = false) => {
    try {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();

      setLoading(true);
      const params = {
        limit,
        offset: reset ? 0 : (pagination.page - 1) * limit,
        ...filters,
        most_sold: filters.most_sold ? 'true' : 'false'
      };

      const response = await api.get('/api/recent_sales', {
        params,
        signal: abortControllerRef.current.signal
      });

      setPagination(prev => ({
        ...prev,
        hasMore: response.data.data.length === params.limit,
        total: response.data.pagination.total
      }));

      setSales(prev => 
        reset ? response.data.data : [...prev, ...response.data.data]
      );
    } catch (error) {
      if (error.name !== 'AbortError') console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  }, [filters, pagination.page, limit]);

  useEffect(() => {
    const debouncedFetch = debounce(() => fetchSales(true), 500);
    debouncedFetch();
    return () => debouncedFetch.cancel();
  }, [filters]);

  useEffect(() => {
    if (pagination.page > 1) fetchSales();
  }, [pagination.page]);

  const lastElementRef = useCallback(node => {
    if (loading || !pagination.hasMore) return;
    if (observerRef.current) observerRef.current.disconnect();
    
    observerRef.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setPagination(prev => ({...prev, page: prev.page + 1}));
      }
    });
    
    if (node) observerRef.current.observe(node);
  }, [loading, pagination.hasMore]);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollToTop(window.pageYOffset > 500);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const getEventDetails = (type) => {
    switch (type) {
      case 'BID':
        return { color: 'text-yellow-400', label: 'Bid', icon: <FaMoneyBillWave /> };
      case 'SALE':
        return { color: 'text-green-400', label: 'Sale', icon: <FaExchangeAlt /> };
      case 'INTERNAL_SALE':
        return { color: 'text-purple-400', label: 'Internal', icon: <FaExchangeAlt /> };
      default:
        return { color: 'text-gray-400', label: 'Event', icon: <FaGift /> };
    }
  };

  return (
    <div className="min-h-screen bg-[#181818] text-white">
      <div className="mx-auto p-4 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
        <div className="text-center mb-6">
          <h1 className="text-xl md:text-3xl font-bold mb-2">
            Recent NFT Gift Transactions
          </h1>
          <p className="text-[#B0B0B0] text-xs md:text-xl">
            Track real-time bids and sales transfers of NFT gifts. Updated every 2 hours.
          </p>
        </div>

        <div className="mb-6 space-y-4">
        <div className="flex flex-row items-center gap-4">
          <div className="relative flex-1">
            <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-[#B0B0B0]" />
            <input
              type="text"
              placeholder="Search gifts..."
              className="w-full p-3 pl-12 pr-3 rounded-xl bg-[#292929] text-white placeholder-[#B0B0B0] focus:outline-none"
              value={filters.search}
              onChange={(e) => setFilters(prev => ({ ...prev, search: e.target.value }))}
              disabled={filters.most_sold}
            />
          </div>
          <button 
            onClick={() => setShowFilters(!showFilters)}
            className="p-3 rounded-xl bg-[#292929] hover:bg-[#363636] transition-colors self-stretch flex items-center"
          >
            {showFilters ? (
              <FaTimes size={23} className="text-[#B0B0B0]" />
            ) : (
              <FaFilter size={23} className="text-[#B0B0B0]" />
            )}
          </button>
        </div>

          {showFilters && (
            <div className="bg-[#232323] rounded-xl p-4 space-y-4">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div className="space-y-2">
                  <div className="flex items-center gap-2 text-[#B0B0B0]">
                    <FaExchangeAlt className="text-sm" />
                    <label className="text-sm">Timeframe</label>
                  </div>
                  <select
                    value={filters.timeframe}
                    onChange={(e) => setFilters(prev => ({ ...prev, timeframe: e.target.value }))}
                    className="w-full p-2 pr-8 rounded-md bg-[#292929] appearance-none bg-select-arrow bg-no-repeat bg-[length:16px_16px] bg-[center_right_0.5rem]"
                  >
                    <option value="24h">24 Hours</option>
                    <option value="7d">7 Days</option>
                    <option value="30d">30 Days</option>
                    <option value="all">All Time</option>
                  </select>
                </div>

                <div className="space-y-2">
                  <div className="flex items-center gap-2 text-[#B0B0B0]">
                    <FaList className="text-sm" />
                    <label className="text-sm">Type</label>
                  </div>
                  <select
                    value={filters.type}
                    onChange={(e) => setFilters(prev => ({ ...prev, type: e.target.value }))}
                    className="w-full p-2 pr-8 rounded-md bg-[#292929] appearance-none bg-select-arrow bg-no-repeat bg-[length:16px_16px] bg-[center_right_0.5rem]"
                    disabled={filters.most_sold}
                  >
                    <option value="ALL">All Types</option>
                    <option value="BID">Bids</option>
                    <option value="SALE">Sales</option>
                    <option value="INTERNAL_SALE">Internal</option>
                  </select>
                </div>

                <div className="space-y-2">
                  <div className="flex items-center gap-2 text-[#B0B0B0]">
                    <FaSort className="text-sm" />
                    <label className="text-sm">Sort By</label>
                  </div>
                  <select
                    value={filters.sort}
                    onChange={(e) => setFilters(prev => ({ ...prev, sort: e.target.value }))}
                    className="w-full p-2 pr-8 rounded-md bg-[#292929] appearance-none bg-select-arrow bg-no-repeat bg-[length:16px_16px] bg-[center_right_0.5rem]"
                  >
                    <option value="date">Newest First</option>
                    <option value="price_desc">Highest Price</option>
                  </select>
                </div>

                <div className="space-y-2 flex items-end">
                  <label className="flex items-center gap-2 p-2 w-full h-[42px] rounded-md bg-[#292929] cursor-pointer hover:bg-[#1a1a1a]">
                    <input
                      type="checkbox"
                      checked={filters.most_sold}
                      onChange={(e) => {
                        const mostSold = e.target.checked;
                        setFilters(prev => ({
                          ...prev,
                          most_sold: mostSold,
                          ...(mostSold ? { search: '', type: 'ALL' } : {}),
                        }));
                      }}
                      className="w-4 h-4 text-blue-500 rounded focus:ring-blue-500"
                    />
                    <span className="text-sm">Most Sold</span>
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
          {sales.map((sale, index) => {
            const eventDetails = getEventDetails(sale.type);
            return (
              <div 
                key={`${sale.id}-${index}`}
                ref={index === sales.length - 1 ? lastElementRef : null}
                className="bg-[#232323] rounded-xl p-4 hover:bg-[#292929] transition-colors"
              >
                <div className="flex items-center justify-between mb-2">
                  <div className="flex items-center gap-3">
                    <div className={`text-2xl ${eventDetails.color}`}>
                      {eventDetails.icon}
                    </div>
                    <div>
                      <h3 className="font-semibold text-base md:text-lg">
                        {sale.gift_name}                       
                      </h3>
                      <div className="text-xs md:text-sm text-[#B0B0B0]">
                        <span>Gift #{sale.gift_num}</span>
                        <span className="mx-2">•</span>
                        <span>Collection {sale.collection?.name_site}</span>
                      </div>
                    </div>
                  </div>
                  
                  <div className="text-right">
                    <div className="flex items-center gap-1 text-base md:text-lg font-semibold">
                      <img src={TON_ICON} className="w-5 h-5" alt="TON" />
                      {sale.price.toFixed(2)}
                    </div>
                    <div className="text-xs md:text-sm text-[#B0B0B0]">
                      {timeAgo.format(new Date(sale.event_timestamp))}
                    </div>
                  </div>
                </div>

                <div className="flex justify-between items-center mt-2 text-xs md:text-sm">
                  <span className="text-[#B0B0B0]">
                    {eventDetails.label}
                  </span>
                  <div className="text-[#B0B0B0]">
                    Transaction ID: #{sale.id}
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {loading && <Loader className="my-8" />}
        {!pagination.hasMore && sales.length > 0 && (
          <div className="text-center py-4 text-gray-400">
            No more transactions to load
          </div>
        )}
        {sales.length === 0 && !loading && (
          <div className="text-center py-8 text-gray-400">
            No transactions found
          </div>
        )}

        {showScrollToTop && (
          <button
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            className="fixed bottom-8 right-8 text-3xl p-3 bg-[#292929] rounded-full shadow-md"
          >
            <IoIosArrowRoundUp className="text-white" />
          </button>
        )}
      </div>
    </div>
  );
};

export default RecentSalesPage;
